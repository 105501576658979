<template>
  <div>
  <v-row style="height: 100%" align="center" no-gutters v-if="$regions.current.region == 'sfo'">
    <img
      src="@/assets/sfo/logo.png"
      alt="Дзюдо СФО"
      style="height: 48px; width: 52px"
    />
    <span class="ml-3 header-bar-title">Дзюдо СФО</span>
  </v-row>
  <v-row class="tech" style="height: 100%" align="center" no-gutters v-else-if="$regions.current.region != 'sfo'">
    <div>
      ДЗЮДО
    </div>
    tech
  </v-row>
  </div>
</template>

<style scoped>
.tech{
  display: flex;
  gap: 3px;
  font-size: 24px;
  font-weight: 300 !important;
  color:black;
}
.tech div{
  padding: 2px;
  color: white;
  font-weight: 700 !important;
  background: var(--main_accent)
}
</style>>

</style>

<script>
export default {
  computed: {
    domain() {
      return window.location.hostname
    }
  }
}
</script>