import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import api from '../api.js'
import regions from "../../regions"


Vue.use(VueRouter)

var routes = [
  {
    path: '/titr_module',
    name: 'Управление титровальным модулем',
    component: () => import('../views/TitrController.vue')
  },

  {
    path: '/report',
    name: 'Форма связи',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/restore/:id',
    name: 'Восстановление пароля',
    component: () => import('../views/Restore.vue')
  },
  {
    path: '/logout',
    name: 'Выход..',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/exit-shadow-login',
    name: 'Выход..',
    component: () => import('../views/ExitShadow.vue')
  },
  {
    path: '/profile',
    name: 'Личный кабинет',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/instruction',
    name: 'Инструкция',
    component: () => import('../views/Instruction.vue')
  },
  {
    path: '/contacts',
    name: 'Контакты',
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/user_agreement',
    name: 'Пользовательское соглашение',
    component: () => import('../views/UserAgreement.vue')
  },
  {
    path: '/privacy',
    name: 'Обработка персональных данных',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/register',
    name: 'Регистрация личного кабинета',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/login',
    name: 'Вход в систему',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/registration/:id',
    name: 'Регистрация спортсменов',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/management/:id',
    name: 'Управление',
    component: () => import('../views/Management.vue')
  },
  {
    path: '/list/:id',
    name: 'Общий список спортсменов',
    component: () => import('../views/PublicList.vue')
  },
  {
    path: '/grid/:id',
    name: 'Сетка соревнований',
    component: () => import('../views/Grid.vue')
  },
  {
    path: '/fights/:id',
    name: 'Порядок встреч',
    component: () => import('../views/Fights.vue')
  },
  {
    path: '/fights/:id/fullscreen',
    name: 'Порядок встреч',
    component: () => import('../views/FightsFullscreen.vue')
  },
  {
    path: '/games',
    name: 'Список соревнований',
    component: () => import('../views/Games.vue')
  },
  {
    path: '/',
    name: 'Главная',
    redirect: to => {
      return { path: '/games', query: { q: to.params.searchText } }
    }
  },
  // {
  //   path: '/test/olymp/43',
  //   name: 'olymp 43',
  //   component: () => import('../views/Test/olymp43.vue')
  // }, 
  // {
  //   path: '/test/olymp/79',
  //   name: 'olymp 79',
  //   component: () => import('../views/Test/olymp79.vue')
  // },
  // {
  //   path: '/test/olymp/147',
  //   name: 'olymp 147',
  //   component: () => import('../views/Test/olymp147.vue')
  // },
  // {
  //   path: '/test/loop',
  //   name: 'loop regular',
  //   component: () => import('../views/Test/loop.vue')
  // },
  // {
  //   path: '/test/mix',
  //   name: 'mix regular',
  //   component: () => import('../views/Test/mix.vue')
  // },
  {
    path: '/403',
    name: 'Доступ запрещен',
    component: () => import('../views/403.vue')
  },
  {
    path: '*',
    name: 'Страница не найдена',
    component: async () => {
      var aliases = await api.rest.GET_ALIASES();

      var finded = aliases.find(x => x.alias == window.location.href.split('/')[3]);
      if (finded)
        window.location.href = finded.to;
      else
        return import('../views/404.vue')
    }
  },
]


if(store.state.user?.admin || store.state.user?.secretary) {
  routes.push( {
    path: '/apps',
    name: 'Приложения секретариат',
    component: () => import('../views/Apps.vue')
  })
}

if(store.state.user?.admin){
  var admin = [
    {
      path: '/admin',
      name: "Управление сервисом",
      redirect: to => {
        return { path: '/admin/games' }
      }
    },
    // {
    //   path: '/test',
    //   name: "test",
    //   component: () => { return import('../views/Test.vue')}
    // },
  ];

  [
    ":type",
    ":type/:id",
  ].forEach(path => {
    admin.push(
      {
        path: `/admin/${path}`,
        name: "Управление сервисом",
        component: () => { return import('../views/Admin/Admin.vue')}
      },
    )
  });

  routes = routes.concat(admin);
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
