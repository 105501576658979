<style >
.tip {
  text-align: center;
  padding: 16px;
  width: 100%;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tip.errored {
  background: rgba(255, 55, 55, 0.3) !important;
  border: 2px solid #ff3737 !important;
}
.tip.successed {
  background: rgba(3, 182, 63, 0.8) !important;
  border: 2px solid #029b0a !important;
}
</style>

<template>
  <div>
    <v-snackbar class="pa-0" v-model="tip.enabled" :timeout="1400">
      <div style="display: flex; gap: 8px; color: white; align-items: center">
        <v-icon v-if="tip.success" style="color: green !important"
          >mdi-check-bold</v-icon
        >
        <v-icon v-else style="color: red !important">mdi-close</v-icon>

        <span style="width: 100%">{{ tip.text }}</span>
        <v-btn variant="text" @click="tip.enabled = false"> OK </v-btn>
      </div>
      <template v-slot:actions> </template>
    </v-snackbar>

    <div class="VERSION">
      {{ $BuildVersion }}
    </div>
    <div class="DEBUGER" v-if="$store.state.user.admin && false">
      <span
        >BP: {{ $vuetify.breakpoint.name }} PWAsupport:
        {{ !!PWA.Handler }}</span
      >
    </div>

    <div v-if="!!PWA.Handler" class="pwa-back">
      <div class="pwa-promt" v-click-outside-my="ClosePWA">
        <div class="informer">
          <img src="logo.png" />
          <span class="hint"
            >Установите веб-приложение {{ $regions.current.brand }} на Ваше
            устройство, и имейте доступ к сервису по одному нажатию</span
          >
        </div>
        <div class="actions">
          <Button small @click="InstallPWA">Установить</Button>
          <Button small red outlined @click="ClosePWA">Нет, спасибо</Button>
        </div>
      </div>
    </div>

    <div
      v-if="$isDeveloping && isUIVisible && !isShadowLogin"
      style="
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        height: 40px;
        background: #c40000;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: white;
      "
    >
      <v-icon style="color: currentColor" size="20">mdi-alert</v-icon>
      <span>РЕЖИМ ЛОКАЛЬНОЙ РАЗРАБОТКИ</span>
      <v-icon style="color: currentColor" size="20">mdi-alert</v-icon>
    </div>

    <div
      v-if="isShadowLogin"
      style="
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 99999;
        height: 40px;
        background: #2e2e2e;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: white;
      "
    >
      <span
        >Вы видите страницу как
        <b style="font-weight: 800 !important"
          >{{ $store.state.user.surname }} {{ $store.state.user.name }}
          {{ $store.state.user.patronymic }}</b
        ></span
      >
    </div>

    <v-app>
      <!-- PC -->
      <v-app-bar
        v-if="isUIVisible"
        app
        flat
        class="header-bar pc d-none d-lg-block"
      >
        <v-container>
          <v-row no-gutters justify="space-between">
            <v-col cols="3">
              <a href="/" class="router">
                <BrandLogo />
              </a>
            </v-col>
            <v-col cols="6" :style="routerWrapper">
              <v-row
                style="height: 100%"
                justify="center"
                align="center"
                no-gutters
              >
                <a
                  v-for="n in router.about"
                  :key="n._id"
                  :href="n.router"
                  class="router about mx-3"
                  :class="{ selected: router.selected == n.router }"
                  >{{ n.text }}</a
                >
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row
                style="height: 100%"
                justify="end"
                align="center"
                no-gutters
              >
                <a
                  class="router account ml-6"
                  href="/profile"
                  v-if="isLoggined"
                >
                  <v-avatar class="ml-n10" size="30" style="position: absolute">
                    <v-img :src="$store.state.user.avatar" alt="" />
                  </v-avatar>

                  <span
                    class="router account"
                    :style="{
                      'font-weight': $store.state.user.admin
                        ? '700 !important'
                        : '',
                    }"
                    :class="{ selected: router.selected == 'profile' }"
                    >{{ $store.state.user.username }}</span
                  >
                </a>

                <a
                  v-for="n in router.account"
                  :key="n._id"
                  :href="n.router"
                  class="router account ml-6"
                  :class="{ selected: router.selected == n.router }"
                  >{{ n.text }}</a
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <!-- MOBILE -->

      <v-app-bar
        v-if="isUIVisible"
        flat
        app
        class="header-bar d-block d-lg-none"
      >
        <v-container>
          <v-row no-gutters justify="space-between">
            <v-col cols="10">
              <a href="/" class="router">
                <BrandLogo />
              </a>
            </v-col>

            <v-col cols="2">
              <v-row
                no-gutters
                justify="end"
                align="center"
                style="height: 100%"
              >
                <v-menu
                  :ripple="true"
                  :rounded="false"
                  nudge-bottom="60"
                  min-width="30%"
                  bottom
                  :close-on-content-click="true"
                  style="box-shadow: unset !important"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="noRipple" size="35" v-bind="attrs" v-on="on"
                      >mdi-menu</v-icon
                    >
                  </template>

                  <v-list
                    min-width="100%"
                    class="pa-2"
                    style="
                      box-shadow: unset !important;
                      width: 250px;
                      min-height: 60px;
                    "
                  >
                    <Menu
                      v-for="n in router.about"
                      :key="n._id"
                      secondary
                      noGutters
                      @click="$router.push(n.router)"
                      >{{ n.text }}</Menu
                    >

                    <v-divider class="my-3 mx-1"></v-divider>
                    <Menu
                      :red="n.router == '/logout'"
                      v-for="n in router.account"
                      :key="n._id"
                      secondary
                      noGutters
                      @click="$router.push(n.router)"
                      >{{ n.text }}</Menu
                    >
                  </v-list>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <v-main :style="isUIVisible ? 'padding: 64px 0px 0px' : ''">
        <!-- <v-row no-gutters align="center" class="page-title mt-n2" :style="{'height': isPhoneSize ? '90px' : '112px'}">
      <div class="mx-auto" style="width: 100%; max-width: 1600px">
        <span :style="{'font-size': isPhoneSize ? '20px' : '48px'}" class="page-title-left">{{$route.name}}</span>
      </div>
    </v-row> -->

        <!-- <v-row align="center" :justify="isPhoneSize ? 'center' : 'start'" class="pa-3 px-6" :style="{'height': isPhoneSize ? '90px' : '112px', background: 'var(--main_accent)'}" >
        <span :style="{'font-size': isPhoneSize ? '20px' : '48px',  color: 'white', 'font-weight': '400 !important'}" >Список соревнований</span>
    </v-row> -->
        <div
          v-if="isUIVisible && router.selected != '/'"
          class="mt-n2"
          :style="{ background: 'var(--main_accent)' }"
        >
          <v-container>
            <v-row
              align="center"
              class="pa-3"
              :style="{ 'min-height': isPhoneSize ? '90px' : '112px' }"
            >
              <span
                :style="{
                  'font-size': isPhoneSize ? '34px' : '48px',
                  color: 'white',
                  'font-weight': '400 !important',
                }"
                >{{ $route.name }}</span
              >
            </v-row>
          </v-container>
        </div>

        <v-container
          :style="{
            'max-width': router.selected != '/' ? 'unset' : '100%',
            padding: isUIVisible ? '12px' : 'unset',
          }"
        >
          <router-view style="padding-bottom: 300px" />
        </v-container>
      </v-main>

      <v-footer v-if="isUIVisible" padless color="var(--main_accent)">
        <v-row
          style="max-width: 1000px; color: white"
          no-gutters
          class="mx-auto mt-4 px-8"
        >
          <v-col cols="12">
            <v-row no-gutters class="mx-auto" justify="space-between">
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <p>
                  <a href="/privacy">Обработка персональных данных</a>
                </p>
                <p>
                  <a href="/user_agreement">Пользовательское соглашение</a>
                </p>
                <p>
                  <a href="/titr_module">Управление титровальным модулем</a>
                </p>
                <p v-if="$regions.current.region == 'sfo'">
                  <a href="/contacts">Контакты</a>
                </p>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                <div>
                  <div class="mt-n1" v-if="$regions.current.support">
                    <a
                      target="_blank"
                      :href="$regions.current.support"
                      style="text-decoration: none !important"
                    >
                      <v-row no-gutters align="center">
                        <v-img
                          style="max-width: 35px; height: 35px; fill: white"
                          src="@/assets/icons/telegram.svg"
                        ></v-img>
                        <span class="ml-2">Группа тех.поддержки</span>
                      </v-row>
                    </a>
                  </div>

                  <div class="mt-1" v-if="$regions.current.telegram">
                    <a
                      target="_blank"
                      :href="$regions.current.telegram"
                      style="text-decoration: none !important"
                    >
                      <v-row no-gutters align="center">
                        <v-img
                          style="max-width: 35px; height: 35px; fill: white"
                          src="@/assets/icons/telegram.svg"
                        ></v-img>
                        <span class="ml-2">Телеграм канал</span>
                      </v-row>
                    </a>
                  </div>

                  <div class="mt-2 ml-1" v-if="$regions.current.youtube">
                    <a
                      target="_blank"
                      :href="$regions.current.youtube"
                      style="text-decoration: none !important"
                    >
                      <v-row no-gutters align="center">
                        <v-icon size="35">mdi-youtube</v-icon>
                        <span class="ml-2">YouTube</span>
                      </v-row>
                    </a>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider
              class="my-2 mt-n2"
              style="background: white; min-height: 1px !important"
            ></v-divider>
            <v-row no-gutters justify="center">
              <span style="text-align: center">
                © Все права защищены <b>{{ $regions.domain }}</b>
                {{ new Date().getFullYear() }}
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>

    <v-dialog
      persistent
      v-model="UpdateRequired"
      width="500"
      style="border-radius: 16px !important"
    >
      <v-container class="white pa-4" style="border-radius: 16px !important">
        <h2>Доступно новое обноление</h2>
        <span class="mt-2 d-block"
          >Перезагрузите сайт {{ $regions.current.brand }} для продолжения</span
        >
        <v-row no-gutters>
          <Button @click="UpdgradeFront()" class="mt-4">Перезагрузить</Button>
        </v-row>
      </v-container>
    </v-dialog>

    <v-dialog
      persistent
      v-if="!$store.state.user?.admin"
      v-model="WebDisabled.disabled"
      width="400"
      style="border-radius: 16px !important"
    >
      <v-container class="white pa-6" style="border-radius: 16px !important">
        <v-row justify="center" class="my-4" no-gutters>
          <img
            class="cog"
            src="@/assets/cog.svg"
            alt=""
            style="width: 150px; height: 150px"
          />
          <v-col style="max-width: 75px">
            <v-row no-gutters>
              <img
                class="cog"
                src="@/assets/cog.svg"
                alt=""
                style="width: 75px; height: 75px"
              />
            </v-row>
            <v-row no-gutters>
              <img
                class="cog"
                src="@/assets/cog.svg"
                alt=""
                style="width: 50px; height: 50px"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters class="mt-8">
          <h2 style="text-align: centerе">
            {{ $regions.current.brand }} временно недоступен
          </h2>
        </v-row>
        <v-row class="mt-4" no-gutters
          ><span>{{ WebDisabled.reason }}</span></v-row
        >
      </v-container>
    </v-dialog>

    <!-- <v-dialog v-if="false"
      persistent
      v-model="$store.state.GlobalError"
      width="400"
      style="border-radius: 16px !important"
    >
      <v-container class="white pa-6" style="border-radius: 16px !important">
        <v-row justify="center" class="my-4" no-gutters>
          <v-icon size="140" color="red">mdi-alert</v-icon>
        </v-row>
        <v-row justify="center" no-gutters class="mt-1">
          <h2>Сервер недоступен</h2>
        </v-row>
        <v-row class="mt-4" no-gutters
          ><span
            >Проверьте интернет-подключение или повторите попытку позже</span
          ></v-row
        >

        <v-row class="mt-6" no-gutters>
          <Button @click="RefreshPage()" block>Обновить страницу</Button>
        </v-row>
      </v-container>
    </v-dialog> -->
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { email } from "vee-validate/dist/rules";
import BrandLogo from "../regions/BrandLogo.vue";

// import EventBus from './event-bus.js'

extend("email", email);
extend("max", {
  validate(value, args) {
    return value.length <= args.length;
  },
  params: ["length"],
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});
extend("not_match", {
  validate() {
    return {
      required: true,
      valid: false,
    };
  },
  message: "Поля не совпадают",
  computesRequired: true,
});
extend("login", {
  validate(value) {
    return {
      required: true,
      valid: /^[a-z]+([-_]?[a-z0-9]+){0,2}$/i.test(value),
    };
  },
  message: "Лоигн содержит запрещённые символы. Допустимы: a-Z",
  computesRequired: true,
});
extend("required", {
  validate(value) {
    return {
      required: true,
      valid: ["", null, undefined].indexOf(value) === -1,
    };
  },
  message: "Это поле необходимо",
  computesRequired: true,
});

export default {
  name: "App",
  components: {
    BrandLogo,
  },
  data() {
    return {
      tip: {
        enabled: false,
        success: false,
        text: undefined,
      },
      isUIVisible: true,
      UpdateRequired: false,
      PWA: {
        Handler: null,
        isCanceled: false,
      },
      WebDisabled: {
        disabled: false,
      },
    };
  },
  methods: {
    UpdgradeFront() {
      location.reload(true);
    },
    InstallPWA() {
      this.PWA.Handler.prompt();

      this.PWA.Handler.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          this.ClosePWA();
        } else {
          this.ClosePWA();
        }
        this.PWA.Handler = null;
      });
    },
    ClosePWA() {
      this.PWA.isCanceled = true;
      this.PWA.Handler = false;
    },
    RefreshPage() {
      window.location.reload();
    },
  },

  computed: {
    isShadowLogin() {
      return !!this.$store.state.original_access_token;
    },
    routerWrapper() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return { "padding-left": "60px", "padding-right": "60px" };
        default:
          return {};
      }
    },
    isLoggined() {
      return this.$store.state?.user?.access_token != null;
    },

    isPhoneSize() {
      return ["xs", "md", "sm"].indexOf(this.$vuetify.breakpoint.name) != -1;
    },
    router() {
      var account;
      var about;

      if (this.isLoggined) {
        about = [
          { text: "Профиль", router: "/profile" },
          { text: "Соревнования", router: "/games" },
          { text: "Контакты", router: "/contacts" },
          { text: "Инструкция", router: "/instruction" },
          { text: "Форма связи", router: "/report" },
        ];

        if (!this.isShadowLogin)
          account = [{ text: "Выход", router: "/logout" }];
        else account = [{ text: "Назад в ЛК", router: "/exit-shadow-login" }];

        if (this.$store.state.user.admin) {
          delete about.splice(about.length - 1, 1);
          about.push({ text: "Админ-панель", router: "/admin" });
        }

        if (this.$store.state.user.apps || this.$store.state.user.admin) {
          about.push({ text: "Приложения", router: "/apps" });
        }
      } else {
        about = [
          { text: "Соревнования", router: "/games" },
          { text: "Контакты", router: "/contacts" },
          { text: "Инструкция", router: "/instruction" },
        ];
        account = [
          { text: "Вход", router: "/login" },
          { text: "Регистрация", router: "/register" },
        ];
      }

      if (this.$regions.current.region != "sfo") {
        about.splice(about.indexOf(about.find((x) => x.text == "Контакты")), 1);
      }

      return {
        selected: this.$route.path,
        about,
        account,
      };
    },
  },
  async beforeCreate() {
    this.$OFFSETS.offsets = await this.$API.OFFSETS();
    var webStatus = await this.$API.DATABASE.STATUS();
    if (webStatus && !webStatus.error) {
    } else {
      this.WebDisabled.disabled = true;
      this.WebDisabled.reason = webStatus.error;
    }
  },
  async created() {
    let favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.setAttribute("rel", "icon");
      favicon.setAttribute("type", "image/png");
      document.head.appendChild(favicon);
    }
    favicon.setAttribute("href", this.$regions.current.favicon);

    this.$eventBus.$on("hideUI", () => {
      this.isUIVisible = false;
    });
    this.$eventBus.$on("ShowToast", (text, success) => {
      this.tip.text = text;
      this.tip.success = success;
      this.tip.enabled = true;
    });
    // Предложение установить PWA
    // window.addEventListener("beforeinstallprompt", (e) => {
    //   e.preventDefault();
    //   if (!this.PWA.isCanceled) this.PWA.Handler = e;
    // });

    if (this.isLoggined && this.$route.path != '/loggout') {
      var me = await this.$API.PROFILE.ME();
      if (me && !me.error) {
        this.$store.commit("UpdateUser", me);
      } else {
        this.$router.push("/logout");
      }
    }

    // .....................
    var reconnect = false;

    this.$socket.on("successConnect", () => {
      if (!reconnect) return;
      this.$eventBus.$emit(
        "ShowToast",
        "Подключение с сервером восстановлено",
        true
      );
      reconnect = false;
    });

    this.$socket.on("disconnect", () => {
      this.$eventBus.$emit("ShowToast", "Потерянно соединение с сервером");
      reconnect = true;
    });
    // .....................

    this.$socket.on("reload", () => {
      location.reload();
    });

    this.$socket.on("updateRequired", () => {
      this.UpdateRequired = true;
    });
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$regions.current.brand}`,
      title: this.$route.name,
      meta: [
        {
          hid: "og:site_name",
          name: "og:site_name",
          content: this.$regions.current.brand,
        },
        {
          hid: "og:title",
          name: "og:title",
          content: this.$regions.current.brand,
        },
        { hid: "og:locate", name: "og:locate", content: "ru_RU" },
        {
          hid: "og:description",
          name: "og:description",
          content: `Сервис проведения соренований ${this.$regions.current.brand}`,
        },
        {
          hid: "description",
          name: "description",
          content: `Сервис проведения соренований ${this.$regions.current.brand}`,
        },
        {
          hid: "og:url",
          name: "og:url",
          content: `https://${this.$regions.domain}`,
        },
      ],
    };
  },
};
</script>

<style >
.vue-range-slider.slider-component .slider .slider-dot {
  z-index: 2 !important;
}
.v-main__wrap {
  background: linear-gradient(0deg, var(--bg_gradiend), var(--bg_gradiend)),
    var(--background) !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: 50% 30% !important;
}
</style>

<style>
@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.pwa-back .pwa-promt {
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  z-index: 9;
  background: white;
  border-radius: 12px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  max-width: 500px;
}

.pwa-back .pwa-promt .actions {
  display: flex;
  gap: 8px;
  align-items: center;
}
.pwa-back .pwa-promt .informer .hint {
  font-size: 16px;
}
.pwa-back .pwa-promt .informer {
  display: flex;
  align-items: center;
}

.pwa-back .pwa-promt .informer img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.pwa-back {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 32px;
  position: fixed;
  overflow: hidden;
  z-index: 6;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}

.DEBUGER {
  position: fixed;
  top: 0;
  left: 0;
  padding: 3px;
  z-index: 9999;
  background: rgb(225, 0, 255);
  color: white;
}
html {
  scroll-behavior: smooth !important;
}

.v-footer * {
  text-decoration: 0;
  color: white !important;
  margin-bottom: 5px !important;
}

.router {
  text-decoration: none;
}

.router.about {
  font-weight: 400 !important;
  font-size: 15px;
  text-decoration: none;
  color: rgb(117, 117, 117);
}

.router.account {
  font-weight: 400 !important;
  font-size: 20px;
  text-decoration: none;
  color: rgb(0, 0, 0);
}

/* .router.selected,
.router.about:hover,
.router.account:hover{
  box-shadow:
    inset 0 -0.155em white,
    inset 0 -0.2em #000;
} */

.header-bar .v-toolbar__content {
  min-height: 60px !important;
  padding-top: 0 !important;
}
* {
  letter-spacing: 0.5px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.header-bar {
  background: white !important;
  min-height: 60px !important;
}

.header-bar-title {
  color: black;
  font-size: 23px;
}
</style>

<style>
.category {
  font-size: 20px;
  font-weight: 700 !important;
}

.category.count {
  font-weight: 500 !important;
}

.rg-title {
  font-size: 25px;
  text-align: center;
}

.rg-title.sub {
  font-size: 20px;
  text-align: center;
}

.form {
  background: #ffffff !important;
  box-shadow: 1px 14px 6px rgba(0, 0, 0, 0.01), 1px 8px 5px rgba(0, 0, 0, 0.03),
    0px 3px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
  width: 400px !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ffc900 #f4f4f4;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffc900;
  border-radius: 10px;
  border: 16px solid #ffc900;
}
</style>


<style scoped>
.VERSION {
  position: fixed;
  top: 5px;
  left: 5px;
  font-size: 11px;
  color: rgb(184, 184, 184);
  z-index: 999;
}
.cog {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>