
var offsets = {};

module.exports = {
    offsets,
    GetSexName(e) {
        var w = this.offsets?.sex?.find(x => x.value == e);
        return !w ? false : w.text;
    },
    GetLevelName(e) {
        var w = this.offsets?.levels?.find(x => x.value == e);
        return !w ? false : w.text;
    },
    GetClassName(e) {
        var w = this.offsets?.classes?.find(x => x.value === e);
        return !w ? false : w.text;
    },
    GetGameLevelName(e) {
        var w = this.offsets?.game_levels?.find(x => x.id == e);
        return !w ? false : w.name
    }
}