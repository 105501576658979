import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import OFFSETS from './OFFSETS.js'
import api from './api.js'
import * as VeeValidate from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru';
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import regions from "../regions"

import utils from "./utils"

Vue.use(VueMask);
Vue.use(VueMeta)
Vue.use(VueTelInput)
Vue.use(VeeValidate);
VeeValidate.localize('ru', ru);


//regions

Vue.prototype.$regions = regions;
document.documentElement.style.setProperty(`--bg_gradiend`, regions.current.bg_gradiend);
document.documentElement.style.setProperty(`--main_accent`, regions.current.main_accent);
document.documentElement.style.setProperty(`--gradient_contrast`, regions.current.gradient_contrast);
document.documentElement.style.setProperty(`--background`, regions.current.background);


import '@/components'
import '@/registerServiceWorker'

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();


Vue.directive('click-outside-my', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

Vue.prototype.$utils = utils;

Vue.prototype.$socket = api.socket;
Vue.prototype.$API = api.rest;
Vue.prototype.$_ = api._;
Vue.prototype.$isDeveloping = process.env.NODE_ENV == "development";
Vue.prototype.$BuildVersion = process.env.VUE_APP_BuildVersion;
Vue.prototype.$OFFSETS = OFFSETS;
Vue.prototype.$store = store;

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')


// Date.prototype.toLocaleDateString
