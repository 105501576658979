import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { user: {} },
  getters: {
  },
  mutations: {
    SetToken(state,payload) {
      state.user.access_token = payload;
    },
    SetShadowLogin(state,payload) {
      state.original_access_token = state.user.access_token ;
      state.user = {};
      state.user.access_token = payload;
      setTimeout(() => {
        return  window.location.replace("/");
      }, 100)
    },
    ExitShadow(state,payload) {
      if(!state.original_access_token) {
        state.original_access_token = undefined;
        setTimeout(() => {
          return window.location.replace("/");
        }, 100)
      }
      state.user = {};
      state.user.access_token = state.original_access_token;
      state.original_access_token = undefined;
      setTimeout(() => {
        return  window.location.replace("/");
      }, 100)
    },
    Login(state, payload) {
      state.user = payload;
      this._vm.$_.refresh();
      router.push("/");
    },
    UpdateUser(state, payload) {
      state.user = payload;
    },
    Logout(state, payload) {
      state.user = {};

      setTimeout(() => {
        window.location.replace("/login")
      }, 1000)
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})
