var moment = require('moment');

function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}
function num_word(value, words) {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
}

// num_word(value, ['пара', 'пары', 'пар']);

var monthNames = [
    "января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
];



//доделать.
function  EndRegisterString(date) {
    var diff = Math.abs(new Date() - new Date(date)) / 1000;
    var left = {
        months: Math.round(diff / (daysInThisMonth() * 86400)),
        days: Math.round(diff / 86400),
        hours: Math.round(diff / 60 / 60),
        minutes: Math.round(diff / 60),
    }

    var string = moment(date);
    string.locale('ru');
    string = string.calendar().toLocaleLowerCase();

    if(left.months > 0 || left.days > 7){
        return `Регистрация до ${string}`;
    }else if(left.days <= 7) {
        return `Регистрация до ${string}`;
         // Регистрация до четрвега (меньше или равно чем неделя )
    }else if (left.days == 0 && left.hours > 0) {
        return `До конца регистрации ${string}`;
            // До конца регистрации 14 часов (меньше дня и больше чем час)
            // До конца регистрации 1 час
    } else if (left.minutes > 0) {
            // До конца регистрации 34 минуты
            // До конца регистрации 50 минут
            return `До конца регистрации ${string}`;
    }else {
        return "Регистрация вот-вот закончится"
    }
};


// num_word(value, ['товар', 'товара', 'товаров']);
// num_word(value, ['штука', 'штуки', 'штук']);

// num_word(value, ['рубль', 'рубля', 'рублей']);

export default {
    EndRegisterString,
    daysInThisMonth,
    num_word
}




