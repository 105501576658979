const defaultDomain = 'judotech.ru';
var domain = window.location.hostname;
var domains = {
    'judotech.ru': {
        brand: "ДзюдоTech",
        region: "tech",
        logo: require("@/assets/tech/logo.png"),
        instruction:[
            require("@/assets/tech/instruction/1.jpg"),
            require("@/assets/tech/instruction/2.jpg"),
            require("@/assets/tech/instruction/3.jpg"),
            require("@/assets/tech/instruction/4.jpg"),
            require("@/assets/tech/instruction/5.jpg"),
            require("@/assets/tech/instruction/6.jpg"),
            require("@/assets/tech/instruction/7.jpg"),
            require("@/assets/tech/instruction/8.jpg"),
            require("@/assets/tech/instruction/9.jpg"),
        ],
        favicon: ("/tech/img/icons/favicon.ico"),
        main_accent: "#4B4B4B",
        gradient_contrast: "#3c3c3c",
        bg_gradiend: 'rgba(75, 75, 75, 0.75)',
        background: `url('${require('@/assets/background/tech.jpg')}')`,
    },
    'judosfo.ru': {
        brand: "Дзюдо СФО",
        region: "sfo",
        logo: require("@/assets/sfo/logo.png"),
        instruction:[
            require("@/assets/sfo/instruction/1.jpg"),
            require("@/assets/sfo/instruction/2.jpg"),
            require("@/assets/sfo/instruction/3.jpg"),
            require("@/assets/sfo/instruction/4.jpg"),
            require("@/assets/sfo/instruction/5.jpg"),
            require("@/assets/sfo/instruction/6.jpg"),
            require("@/assets/sfo/instruction/7.jpg"),
            require("@/assets/sfo/instruction/8.jpg"),
            require("@/assets/sfo/instruction/9.jpg"),
        ],
        favicon: ("/sfo/img/icons/favicon.ico"),
        main_accent: "#0075ff",
        gradient_contrast: "#0050AE",
        bg_gradiend: 'rgba(0, 33, 150, 0.75)',
        background: `url('${require('@/assets/background/sfo.jpeg')}')`,
        youtube: "https://www.youtube.com/c/ДзюдоСФО",
        support: "https://t.me/judosfo_support",
        telegram: "https://t.me/judosfo"
    },
}

domains["sfo.judotech.ru"] = domain["judosfo.ru"]

export default {
    current:  process.env.NODE_ENV === 'development' ? domains["judosfo.ru"] : (domains[domain] || domains[defaultDomain]),
    domain
}

