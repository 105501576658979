import Vue from 'vue'

var objects = [
    "Dialogs/CreateWard",
    "Dialogs/DeleteWard",
    "Dialogs/ConfirmDeletingProfile",
    "Dialogs/ChangeOrgIcon",
    "Dialogs/EditCoaches",
    "Dialogs/AgeSelector",
    "Dialogs/FindSecretar",
    "Dialogs/EditUserData",
    "Dialogs/ConfirmMakeAOwner",
    "Dialogs/EditPerson",
    "EventCard",
    "SmallDatePicker",
    "Coach",
    "UI/GameEditor",
    "Tunnel",
    "SportsmanSlot",
    "Button",
    "Weight",
    "ItemController",
    "TextField",
    "Select",
    "TextArea",
    "Phone",
    "Combobox",
    "CheckBox",
    "OnlyReadField",
    "Error",
    "Menu",
    "Dialog",
    "Ward",
];

objects.forEach(element => {
    var name = element.includes("/") ? element.split("/")[element.split("/").length - 1] : element;
    Vue.component(name, () => import(`../components/${element}.vue`))
});